


















import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import Tag from '@/components/_uikit/Tag.vue'
import MentorQuickStartTable from '@/components/tables/MentorQuickStartTable.vue'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    MentorQuickStartTable,
    Tag,
  },
})
export default class exercisesQuickStartList extends MasterGroupMixin {
  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get exercise() {
    return MentorExercisesModule.exercise
  }

  private get breadcrumbs() {
    return [
      { name: 'Домашняя работа', path: '/manager/education/exercises' },
    ]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    // Note: Для отрисования тэга
    if (!this.exerciseUUID || !this.exercise || this.exerciseUUID !== this.exercise.uuid)
      MentorExercisesModule.fetchExerciseQuickStart({ exerciseUUID: this.exerciseUUID, masterGroupID: this.groupID })
        .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.exercise ? this.exercise.title : 'Проверка дз',
    }
  }
}
