











































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import MentorRateTaskRow from '@/components/views/exercise/mentor/MentorRateTaskRow.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import Select from '@/components/_uikit/controls/Select.vue'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  ExerciseLargeResource, ExerciseMasterResource,
  ExerciseStatus,
  ExerciseStatusSpecialGroupTypeTable,
  ExerciseMastersTable,
  ExerciseMastersTableType,
  Permission,
} from '@/store/types'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { tableFooterOptions } from '@/utils/constants'
import { openNewTab } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonTextIcon,
    MentorRateTaskRow,
    Select,
    TableFooter,
    Tag,
  },
})
export default class MentorQuickStartTable extends Mixins(MasterGroupMixin, PermissionsMixin) {
  @Prop({ required: true })
  private exercise!: ExerciseLargeResource

  private exerciseStatus: ExerciseStatusSpecialGroupTypeTable = null
  private footerOptions = tableFooterOptions
  private hoveredRowIndex = -1
  private isStatusChecked = ExerciseStatus.CHECKED
  private isStatusComplete = ExerciseStatus.COMPLETE

  private get isEnableExport() {
    return this.hasEveryPermissions([
      Permission.MASTER_VIEW,
      Permission.MASTER_LESSONS_VIEW,
      Permission.MASTER_EXERCISES_VIEW,
      Permission.MASTER_HEALING,
      Permission.MASTER_TRANSFER_LITE,
      Permission.MENTOR_LIST_VIEW,
      Permission.MENTOR_MASTER_VIEW,
      Permission.MENTOR_LESSONS_VIEW,
      Permission.MENTOR_EXERCISES_VIEW,
      Permission.EDUCATION_EXERCISES_VIEW,
      Permission.EDUCATION_LESSON_VIEW,
      Permission.DEPOSITORY_MATERIALS_VIEW,
      Permission.DEPOSITORY_MANUAL_VIEW,
    ])
  }

  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get filter() {
    return MentorExercisesModule.masterSpecialGroupFilter
  }

  private set filter(filter: ExerciseMastersTable) {
    MentorExercisesModule.setMastersSpecialGroupFilter(filter)
  }

  private get pagination() {
    return MentorExercisesModule.mastersSpecialGroup.meta
  }

  private get exerciseStatuses () {
    return [
      { name: 'К проверке', value: ExerciseStatus.ON_CHECK },
      { name: 'На проверке', value: ExerciseStatus.CHECKED },
      { name: 'Завершено', value: ExerciseStatus.COMPLETE },
    ]
  }

  private get headers () {
    return [
      { cellClass: 'cell-width-8', sortable: false, text: '#', value: 'id' },
      { sortable: false, text: 'Мастер', value: 'master' },
      { sortable: false, text: 'Статус', value: 'status' },
      { text: 'Дата сдачи', value: 'onCheckAt' },
      { sortable: false, text: 'Оценка', value: 'points' },
      { cellClass: 'cell-width-20', sortable: false, text: 'Сообщения', value: 'newMessageCount' },
    ]
  }

  private get mastersSpecialGroup () {
    const masters = MentorExercisesModule.mastersSpecialGroup.data

    if (!this.exerciseStatus) return masters

    return masters.filter(master => {
      const inOneOfCheckedState = (
        master.status.value === ExerciseStatus.ON_CHECK ||
        master.status.value === ExerciseStatus.CHECKED
      )
      /**
       * Note: Особый случай для фильтрации с наставником
       */
      if (this.exerciseStatus === 'check') {
        return master.hasMentor && inOneOfCheckedState
      }
      else if (this.exerciseStatus === 'to_check') {
        return !master.hasMentor && inOneOfCheckedState
      }
      return master.status.value === this.exerciseStatus
    })
  }

  private get meta () {
    return MentorExercisesModule.mastersSpecialGroup.meta
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private fetchMastersSpecialGroup () {
    /**
     * Note: Здесь приходится немного хитрить со значениями, так как домашки имеют один и тот же статус
     * отличается только наличие поля фильтра для взятых домашек
     */
    // const { status } = this.filter as ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetParams

    MentorExercisesModule.fetchMastersSpecialGroup({
      exerciseUuid: this.exerciseUUID,
      filter: {
        ...this.filter,
        // Note: undefined поскольку Фильтрация осуществляется на фронте
        // ref: getter mastersSpecialGroup
        taken: undefined,
      },
      masterGroupId: this.groupID,
    })
      .catch(this.notifyError)
  }

  private handleFilter (field: ExerciseMastersTableType, value: unknown) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private statusColor(value: ExerciseStatus): string {
    if (value === ExerciseStatus.WAIT) return 'grey'
    if (value === ExerciseStatus.COMPLETE) return 'teal'
    return 'orange'
  }

  private replaceStatus(value: ExerciseStatus) {
    switch (value) {
    case ExerciseStatus.CHECKED:
      return 'К проверке'
    case ExerciseStatus.COMPLETE:
      return 'Завершено'
    case ExerciseStatus.ON_CHECK:
      return 'К проверке'
    case ExerciseStatus.WAIT:
      return 'Не сдано'
    }
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.mastersSpecialGroup.findIndex(_item => _item.id === id)
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.mastersSpecialGroup.findIndex(_item => _item.id === id)
  }

  private handleTakeInWork(master: ExerciseMasterResource) {
    const { id } = master
    if (!master.hasMentor) {
      MentorExercisesModule.takeExerciseInWork({
        exerciseUuid: this.exerciseUUID,
        masterGroupId: this.groupID,
        masterId: id,
      })
        .then(() => {
          this.redirectToMaster(id)
        })
        .catch((error: any) => {
          if (error.response.status === 426) {
            this.$emit('update-list')
          }
          this.notifyError(error)
        })
    } else {
      this.redirectToMaster(id)
    }
  }

  private redirectToMaster(id: number) {
    this.$router.push({
      name: 'manager.education.exercises.item.task.master',
      params: {
        exerciseUUID: this.exerciseUUID,
        groupID: this.groupID.toString(),
        masterID: id.toString(),
        taskUUID: this.exercise.tasks[0].uuid,
      },
    })
  }

  private color(master: ExerciseMasterResource) {
    if (this.isTaskTakenOnCheck(master)) {
      return 'blue'
    } else if (this.isTaskCompleted(master)) {
      return 'teal'
    }
    return 'orange'
  }

  private isTaskTakenOnCheck(master: ExerciseMasterResource) {
    return master.hasMentor && this.isTaskChecked(master)
  }

  private isTaskCompleted(master: ExerciseMasterResource) {
    return master.status.value === ExerciseStatus.COMPLETE
  }

  private isTaskChecked(master: ExerciseMasterResource) {
    return master.status.value === ExerciseStatus.ON_CHECK || master.status.value === ExerciseStatus.CHECKED
  }

  private isTaskForCheck(master: ExerciseMasterResource) {
    return !master.hasMentor && this.isTaskChecked(master)
  }

  private tagMessage(master: ExerciseMasterResource) {
    if (this.isTaskTakenOnCheck(master)) {
      return 'на проверке'
    } else if (this.isTaskCompleted(master)) {
      return 'завершено'
    } else if (this.isTaskForCheck(master)) {
      return 'к проверке'
    }

    return master.status.name.toLowerCase()
  }

  private handleExport() {
    openNewTab(`/manager/master-groups/${this.groupID}/exercises/${this.exerciseUUID}/forms/export`, {})
  }

  @Watch('filter', { deep: true })
  watchFilter() {
    this.fetchMastersSpecialGroup()
  }
}
